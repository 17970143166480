<template>
      <section>
            <div>
                  <div v-if="!show" class="address__table">
                        <div class="col-span-2 type-h3">{{ t("LBL_EMAIL_ADDRESS") }}</div>
                        <div class="address__table-col col-span-2">
                              <text-input :placeholder="t('Email')" @input="getInput($event)" :value="email"></text-input>
                        </div>
                        <div v-if="showError" class="address__table-col col-span-2 text-red-dark">{{ t("MSG_EMAIL_VALIDATION") }}</div>
                        <div>
                              <Button :text="'Abbrechen'" :css-class="'white'" @click="edit"></Button>
                        </div>
                        <div>
                              <Button :text="'Speichern'" :css-class="'blue'" @click="save"></Button>
                        </div>
                  </div>
                  <div class="address__table" v-else>
                        <div class="col-span-2">
                              <div class="type-h3">{{ t("LBL_EMAIL_ADDRESS") }}</div>
                        </div>
                        <div class="col-span-1 flex flex-col justify-center items-end cursor-pointer">
                              <app-image :alt="edit-email" @click="edit()" :src="require(`../../assets/img/icons/edit.svg`)"></app-image>
                        </div>
                        <div class="col-span-3">
                              {{ email }}
                        </div>
                  </div>
            </div>
      </section>
</template>

<script>
      import Button from "@/components/Button/Button";
      import TextInput from "@/components/Inputs/TextInput";
      import AppImage from "@/components/Image/Image";
      import { ref } from "vue";
      import { useI18n } from "vue-i18n";

      export default {
            name: "Email",
            props: {
                  isShow: {
                        type: Boolean
                  },
                  email: {
                        type: String
                  }
            },
            components: {
                  Button,
                  AppImage,
                  TextInput
            },
            setup(props, { emit }) {
                  const { t } = useI18n();
                  const show = ref(props.isShow);
                  const showError = ref(false);
                  const updatedEmail = ref("");

                  const getInput = (input) => {
                        if (isInputValid(input.target.value)) {
                              showError.value = false;
                              updatedEmail.value = input.target.value;
                        } else {
                              showError.value = true;
                        }
                  };

                  const edit = () => {
                        showError.value = false;
                        show.value = !show.value;
                  };

                  const isInputValid = (inputEmail) => {
                        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(inputEmail);
                  };

                  const save = async () => {
                        if (showError.value && updatedEmail.value === "") {
                              return;
                        } else {
                              show.value = !show.value;
                              emit("updateValue", updatedEmail.value);
                        }
                  };

                  return {
                        t,
                        show,
                        showError,
                        getInput,
                        edit,
                        save,
                        updatedEmail
                  };
            }
      };
</script>
